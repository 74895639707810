import React from 'react';
import { Link } from 'react-router-dom';

const PrototypeLandingPage = () => {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-sm-6">
                    <br />
                    <br />
                    <h1>Prototype</h1>

                    <div
                        style={{
                            backgroundColor: '#9800a8',
                            padding: '3rem 2rem',
                            color: '#FFF',
                        }}
                    >
                        <h2>Sider:</h2>
                        <ul className="list--unstyled">
                            <li>
                                <Link
                                    to="/start-page"
                                    style={{
                                        color: '#FFF',
                                        fontWeight: 'bold',
                                        display: 'inline-block',
                                        marginBottom: '0.5rem',
                                    }}
                                >
                                    Startside
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/article-page"
                                    style={{
                                        color: '#FFF',
                                        fontWeight: 'bold',
                                        display: 'inline-block',
                                        marginBottom: '0.5rem',
                                    }}
                                >
                                    Artikkel
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/faq-page"
                                    style={{
                                        color: '#FFF',
                                        fontWeight: 'bold',
                                        display: 'inline-block',
                                        marginBottom: '0.5rem',
                                    }}
                                >
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/kunder"
                                    style={{
                                        color: '#FFF',
                                        fontWeight: 'bold',
                                        display: 'inline-block',
                                        marginBottom: '0.5rem',
                                    }}
                                >
                                    App
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrototypeLandingPage;
